import React from "react"
import GeneralComp from "../../components/generalComp"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { cgpa } from "../../data/academics"
import { getWords } from "../../helper-functions"
function Cgpa(props) {
  return (
    <div>
      <Layout>
        <SEO title={cgpa.title} description={getWords(cgpa.content, 60)} />
        <GeneralComp {...cgpa} />
      </Layout>
    </div>
  )
}

export default Cgpa
